<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <CButton color="primary" @click="create()"
          ><CIcon name="cil-plus" /> Add New Global Language</CButton
        >
        <br />
        <br />
        <br />

        <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>

        <CDataTable
          :items="items"
          :fields="fields"
          :items-per-page="50"
          hover
          pagination
        >
          <template #name="{ item }">
            <td>
              <strong>{{ item.name }}</strong>
            </td>
          </template>
          <template #languageCode="{ item }">
            <td>
              <strong>{{ item.languageCode }}</strong>
            </td>
          </template>
          <template #datetimeFormat="{ item }">
            <td>
              <strong>{{ item.datetimeFormatWidget }}</strong>
            </td>
          </template>
          <template #EDIT="{ item }">
            <td>
              <CButton color="primary" @click="edit(item.id)"
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
          <template #DELETE="{ item }">
            <td>
              <CButton
                color="danger"
                @click="
                  warningModal = true;
                  language = item;
                "
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </td>
          </template>
        </CDataTable>
        <CModal
          v-model="language.id"
          title="Are you sure to delete selected global language?"
          color="danger"
          :show.sync="warningModal"
          @update:show="closeModal"
        >
          <CRow>
            <CCol col="6">
              <CInput label="Name" v-model="language.name" disabled />
            </CCol>
            <CCol col="6">
              <CInput
                label="Language Code"
                v-model="language.languageCode"
                disabled
              />
            </CCol>
          </CRow>
        </CModal>
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "GlobalLanguages",
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      items: [],
      fields: ["name", "languageCode", "datetimeFormat", "EDIT", "DELETE"],
      language: [],
      warningModal: false,

      // Alert
      alertType: "danger",
      message: null,
    };
  },
  computed: {},
  methods: {
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.language.id);
      }
    },

    edit(id) {
      const editLink = `languages/create/${id}`;
      this.$router.push({ path: editLink });
    },
    delete(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/Language/${id}`)

        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted language.";
          self.getList();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      this.$router.push({ path: "languages/create/" + this.emptyGuid });
    },

    getList() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/Language`)
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
